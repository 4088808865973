import React from 'react';
import { BsGithub, BsInstagram } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { IHyperlink } from '../../models/Social';

export default class Home extends React.Component<{}, {}> {
    public render() {
        return(
            <div className='fullwidth'>

				<div className="content">

                    <div id="profile-picture">
                        <img alt="profile" src="/profile.jpg"/>
                    </div>

                    <div className="links">
                        {socials.map(social =>
                            <Link target={"_blank"} to={social.url} rel='noopener noreferrer'>
                                <div key={social.key} className='links-tile small-sized'>
                                    <div style={{color: social.color}} className='links-tile-icon'>{social.icon}</div>
                                    <div className='links-tile-link'>{social.label}</div>
                                </div>
                            </Link>
                        )}
                    </div>

				</div>
				
            </div>
        );
    }
}

const socials: IHyperlink[] = [
    {
        key: "github",
        label: "DerEffi",
        icon: <BsGithub/>,
        url: "https://github.com/DerEffi",
        color: "#4d8fac"
    },
    {
        key: "instagram",
        label: "@JaDerEffi",
        icon: <BsInstagram/>,
        url: "https://www.instagram.com/jadereffi/",
        color: "#E1306C"
    },
    {
        key: "mail",
        label: "info@dereffi.de",
        icon: <FiMail/>,
        url: "mailto:info@dereffi.de",
        color: "#EC4235"
    }
]