import './App.scss';
import Home from './components/pages/Home';
import NotFound from './components/pages/NotFound';
import Footer from './components/shared/Footer';
import Sidebar from './components/shared/Sidebar';
import { ISidebarItem } from './models/SidebarItem';
import 'primeicons/primeicons.css';
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { BiImages, BiInfoCircle, BiLockAlt, BiSun } from 'react-icons/bi';
import { CgMenu } from 'react-icons/cg';
import { TfiLayoutGrid4Alt } from 'react-icons/tfi';
import { Route, Routes } from 'react-router-dom';
import Privacy from './components/pages/Privacy';
import Imprint from './components/pages/Imprint';
import ImageComparison from './components/projects/ImageComparison';
import Pixelart from './components/projects/Pixelart';
import { BsHouseFill } from 'react-icons/bs';

PrimeReact.inputStyle = 'filled';

interface IAppState {
	sidebar: boolean;
	dark: boolean;
}

export default class App extends React.Component<{}, IAppState> {

	private windowWidth: number = 0;
	private resizeEvent = () => {
		if(window.screen.width < 769 && this.windowWidth >= 769 && this.windowWidth !== window.screen.width)
			this.changeSidebar(false);

		this.windowWidth = window.screen.width;
	};

	constructor(props: {}) {
		super(props);

		this.state = {
			sidebar: true,
			dark: true,
		}
	}

	componentDidMount(): void {
		if(window.screen.width < 769)
			this.changeSidebar(false);

		let theme: string | null = localStorage.getItem("theme");
		if(theme && theme === "light")
			this.changeTheme(false);
	
		window.addEventListener("resize", this.resizeEvent);
	}

	componentWillUnmount(): void {
		window.removeEventListener("resize", this.resizeEvent);
	}

	public render(): JSX.Element {
		return(
			<div id='fullpage'>
			<div className='background' style={{background: `linear-gradient(135deg, var(${this.state.dark ? "--indigo-900" : "--surface-300"}) -75%, var(--surface-ground) 100%)`}}></div>
				<div className="sidebar-topbar">
					<div>
						<div>
							<Tooltip target=".sidebar-topbar-item" position='bottom' showDelay={250} />
							<div onClick={() => this.changeSidebar()} className="sidebar-topbar-item">
								<CgMenu/>
							</div>
							<div onClick={() => this.changeTheme()} className="sidebar-topbar-item" data-pr-tooltip="Theme">
								<BiSun/>
							</div>
						</div>
					</div>
				</div>
				<div id='fullpage-content'>
					<div id="sidebar" className={this.state.sidebar ? "sidebar-visible" : "sidebar-hidden"}>
						<Sidebar model={SidebarMenu} onLink={() => {if(window.screen.width < 769) {this.changeSidebar(false);}}} />
					</div>
					<div id="sidebar-layout" className={this.state.sidebar ? "sidebar-layout-sidebar" : "sidebar-layout-full"}>
						<div id="sidebar-block" onClick={() => this.changeSidebar() } />
						<Routes>
							<Route path="/pixelart" element={<Pixelart/>} />
							<Route path="/image-comparison" element={<ImageComparison/>} />
							<Route path="/privacy" element={<Privacy/>} />
							<Route path="/imprint" element={<Imprint/>} />
							<Route path="/" element={<Home/>} />
							<Route path="*" element={<NotFound/>} />
						</Routes>
					</div>
				</div>
				<Footer/>
			</div>
		);
	}

	private changeSidebar(active: boolean = !this.state.sidebar) {
		this.setState({
			sidebar: active
		});
	}

	private changeTheme(dark: boolean = !this.state.dark) {
		let element = document.getElementById('theme-css');
        if(element) {
            let href = element.getAttribute("href");
            if(href) {
                href = href.substring(0, href.lastIndexOf("/"));
                element.setAttribute("href", href + "/" + (dark ? "dark.css" : "light.css"));
				localStorage.setItem("theme", dark ? "dark" : "light");
				this.setState({
					dark: dark
				});
				return;
            }
        }
		console.error("Could not set theme properly - Missing html attributes");
	}
}

const SidebarMenu: ISidebarItem[] = [
    {
        label: 'Home',
        items: [
            {
                label: 'Home',
                url: '/',
				icon: <BsHouseFill/>
            }
        ]
    },
	{
		label: "Projects",
		items: [
			{
				label: 'Pixelart Display',
				url: '/pixelart',
				icon: <TfiLayoutGrid4Alt/>
			},
			{
				label: 'Image Comparison',
				url: '/image-comparison',
				icon: <BiImages/>
			}
		]
	},
    {
        label: 'Info',
        items: [
			{
                label: 'Imprint',
                url: '/imprint',
				icon: <BiInfoCircle/>
            },
			{
                label: 'Privacy',
                url: '/privacy',
				icon: <BiLockAlt/>
            }
        ]
    },
];