import React from 'react';
import { Link } from 'react-router-dom';

export default class Imprint extends React.Component<{}, {}> {
    public render() {
        return(
            <div className='fullwidth'>

				<div className="content">
					
                    <h1 className='headline'>Imprint</h1>

                    <p>DerEffi</p>
                    <p>Germany</p>
                    <p><Link target={"_blank"} to="mailto:info@dereffi.de">info@dereffi.de</Link></p>
                    <p><Link to="https://www.dereffi.de">www.dereffi.de</Link></p>
                    <br/>
                    <p>The Internet pages of DerEffi are not intended for commercial use but private use only and therefore do not require a postal address, imprint or privacy policy at all.</p>

				</div>
				
            </div>
        );
    }
}