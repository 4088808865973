import React from 'react';
import { BiCodeAlt, BiImages } from 'react-icons/bi';
import { HiDownload } from 'react-icons/hi';
import Project from '../shared/Project';

export default class ImageComparison extends React.Component<{}, {}> {
    public render() {
        return <Project
            headline='Image Comparison'
            image='../assets/image-comparison.jpg'
            description="App for comparing large numbers of images within or between directories for duplicates and similarities. Does not rely on resolution or exact pixel by pixel matches to detect similar images"
            features={[
                "Image similarity detection (obviously) based on edge detection rather than pixel by pixel",
                "Preview and details of the images of the recognized matches",
                "In app deletion of images (permanent, to recycle bin or folder on your pc)",
                "Image Analysis Cache, so only new images have to be processed",
                "No-Match Cache, for repeated comparison of the same folders",
                "Different Search modes between directories (all, inclusive, exclusive, list inclusive, list exclusive)",
                "Different Hash modes (Perceptual and Difference Hash)",
                "Adjustables for granularity/detail of the image analysation",
                "Auto Processor for automatic image deletion according to adjustable ruleset",
                "Search Profiles",
                "Hotkeys",
                "Command line tool",
            ]}
            links={[
                {
                    key: "projectsite",
                    label: "Project Site",
                    icon: <BiImages/>,
                    url: "https://github.com/DerEffi/image-comparison",
                    color: "var(--primary-color)",
                },
                {
                    key: "sourcecode",
                    label: "Source Code",
                    icon: <BiCodeAlt/>,
                    url: "https://github.com/DerEffi/image-comparison",
                    color: "#4d8fac"
                },
                {
                    key: "image-comparison",
                    label: "Windows",
                    icon: <HiDownload/>,
                    url: "https://github.com/DerEffi/image-comparison/releases/latest/download/image-comparison-win.zip",
                    color: "var(--green-600)"
                },
            ]}
        />;
    }
}