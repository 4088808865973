import React from 'react';
import { Link } from 'react-router-dom';
import { IHyperlink } from '../../models/Social';

export interface ProjectTemplateProps {
    headline: string;
    image?: string;
    description?: string;
    features?: string[];
    links?: IHyperlink[];
}

export default class Project extends React.Component<ProjectTemplateProps, {}> {
    private renderLink(link: IHyperlink): JSX.Element {
        return  <Link target={"_blank"} to={link.url} rel='noopener noreferrer'>
                    <div key={link.key} className='project-links-tile sized'>
                        <div style={{color: link.color}} className='project-links-tile-icon'>{link.icon}</div>
                        <div className='project-links-tile-link'>{link.label}</div>
                    </div>
                </Link>
    }

    public render() {
        return(
            <div className='fullwidth'>

				<div className="content project">

                    <h1 className='headline'>{this.props.headline}</h1>

                    {this.props.image &&
                        <div className='project-image'>
                            <img src={this.props.image} alt={`example look of ${this.props.headline}`} />
                        </div>
                    }

                    {this.props.links &&
                        <div className="project-links">
                            {this.props.links?.map(this.renderLink)}
                        </div>
                    }

                    {this.props.description &&
                        <div>
                            {this.props.description}
                        </div>
                    }

                    {this.props.features &&
                        <>
                            <h3>Features</h3>
                            <ul>
                                {this.props.features.map((f, i) => <li key={i}>{f}</li>)}
                            </ul>
                        </>
                    }

				</div>
				
            </div>
        );
    }
}