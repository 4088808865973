import React from 'react';
import { HiDownload } from 'react-icons/hi';
import { TfiLayoutGrid4Alt } from 'react-icons/tfi';
import { BiCodeAlt } from 'react-icons/bi';
import Project from '../shared/Project';

export default class Pixelart extends React.Component<{}, {}> {
    public render() {
        return <Project
            headline='Pixelart Display'
            image='../assets/pixelart.jpg'
            description="ESP32 Display Matrix for displaying images, time and social stats on a 64x64 LED Matrix Panel (HUB75E)"
            features={[
                "Display of Images on the LED Matrix",
                "Display of date and time in different styles",
                "Control with on-device hardware buttons",
                "RESTful API including webinterface for remote control (WiFi)",
                "Requesting social media stats from proxy service",
                "OTA updates via SD-Card or WiFi",
            ]}
            links={[
                {
                    key: "projectsite",
                    label: "Project Site",
                    icon: <TfiLayoutGrid4Alt/>,
                    url: "http://pixelart.dereffi.de",
                    color: "var(--primary-color)",
                },
                {
                    key: "sourcecode",
                    label: "Source Code",
                    icon: <BiCodeAlt/>,
                    url: "https://github.com/DerEffi/pixelart",
                    color: "#4d8fac"
                },
                {
                    key: "firmware-5bit",
                    label: "Firmware (5bit)",
                    icon: <HiDownload/>,
                    url: "https://github.com/DerEffi/pixelart/releases/latest/download/firmware-5bit.zip",
                    color: "var(--green-600)"
                },
                {
                    key: "firmware-6bit",
                    label: "Firmware (6bit)",
                    icon: <HiDownload/>,
                    url: "https://github.com/DerEffi/pixelart/releases/latest/download/firmware-6bit.zip",
                    color: "var(--green-600)"
                },
                {
                    key: "webinterface-device",
                    label: "Interface (device)",
                    icon: <HiDownload/>,
                    url: "https://github.com/DerEffi/pixelart/releases/latest/download/webinterface-device.zip",
                    color: "var(--green-600)"
                },
                {
                    key: "webinterface-web",
                    label: "Interface (web)",
                    icon: <HiDownload/>,
                    url: "https://github.com/DerEffi/pixelart/releases/latest/download/webinterface-web.zip",
                    color: "var(--green-600)"
                },
            ]}
        />;
    }
}